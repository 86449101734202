
/* global uiImages */
import React from 'react';
import '../App.css';

import styled from 'styled-components';

import Text from './Text';
import TOU from '../static/TOS2.txt';

import CrossIcon from '../images/crossIcon';

const ModalContainer = styled.div`
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80vw;
  height: 74vh;
  // box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  padding: 20px;
  white-space: pre-wrap;
`;

const TextContainer = styled.div`
  overflow: auto;
  text-overflow: ellipsis; 
  height: 56vh;
  text-align: left;
`;

const ExitCross = styled.button`
  background: none;
  margin-left: 72vw;
  border: none;
`;

const HeaderContainer = styled.div`
  
`;

var textTOU = ""

fetch(TOU)
  .then(r => r.text())
  .then(text => {
    textTOU =  text.split(/\r\n|\r|\n|\t/).filter((el) => { return el.length > 0; })

  });

function Modal (props) {
    const { setModal } = props;

    const section1 = textTOU.slice(1,15)
    const section2 = textTOU.slice(17,46)
    const section3 = textTOU.slice(48,87)
    const section4 = textTOU.slice(89,113)
    const section5 = textTOU.slice(115,167)
    const section6 = textTOU.slice(169,220)
    const section7 = textTOU.slice(222,341)
    const section8 = textTOU.slice(343,390)
    const section9 = textTOU.slice(392,405)
    const section10 = textTOU.slice(407,413)
    const section11 = textTOU.slice(415,426)
    const section12 = textTOU.slice(428,442)
    const section13 = textTOU.slice(444,446)
    const section14 = textTOU.slice(452)
    const section15 = textTOU.slice(448,451)


  return (
    <div>
    <ModalContainer className='modal-container' >
        <ExitCross onClick={() => setModal.setModalState(false)}>
          { (typeof (uiImages) !== 'undefined' && uiImages.tosCloseBtnLogo !== undefined) ? <img alt="" src={uiImages.tosCloseBtnLogo} />
            : <CrossIcon width={20} />}
        </ExitCross>
        <HeaderContainer>
          <Text.Text>{textTOU[0]}</Text.Text>
        </HeaderContainer>
        <br />
        <TextContainer>

        {/* <Text.Heading3 className="modal-date"> { textTOU [1] } </Text.Heading3> */}
          <Text.ParagraphModal>
            {section1.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[16]} </Text.Heading3>
          <Text.ParagraphModal>
            {section2.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[47]} </Text.Heading3>
          <Text.ParagraphModal>
            {section3.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[88]} </Text.Heading3>
          <Text.ParagraphModal>
            {section4.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[114]} </Text.Heading3>
          <Text.ParagraphModal>
            {section5.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[168]} </Text.Heading3>
          <Text.ParagraphModal>
            {section6.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal> 
          <Text.Heading3> {textTOU[221]} </Text.Heading3>
          <Text.ParagraphModal>
            {section7.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[342]} </Text.Heading3> 
          <Text.ParagraphModal>
            {section8.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[391]} </Text.Heading3>
          <Text.ParagraphModal>
            {section9.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[406]} </Text.Heading3> 
          <Text.ParagraphModal>
            {section10.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal> 
          <Text.Heading3> {textTOU[414]} </Text.Heading3>
          <Text.ParagraphModal>
            {section11.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[427]} </Text.Heading3> 
          <Text.ParagraphModal>
            {section12.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[443]} </Text.Heading3> 
          <Text.ParagraphModal>
            {section13.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal>
          <Text.Heading3> {textTOU[447]} </Text.Heading3>
          <Text.ParagraphModal>
            {section15.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal> 

          <Text.Heading3> {textTOU[451]} </Text.Heading3>
          <Text.ParagraphModal>
            {section14.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.ParagraphModal> 
        </TextContainer>
      </ModalContainer>
    </div>

  );
}

export default Modal;
