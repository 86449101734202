import styled from 'styled-components';
import Colors from './Colors';

const Text = styled.span`
  color: ${Colors.LIGHT_BLUE};
  font-family: 'Open Sans', sans-serif;
  font-size: ${({ important }) => (important ? '3.8vh' : '2.9vh')};
  font-style:normal;
  font-weight: ${({ important }) => (important ? '600' : '500')};
`;

const Heading = styled(Text)`
  color: black;
  font-size: 10.3704vh;
  font-weight: 400;
`;

const Heading5 = styled(Text)`
  font-size: 5.5vh;
  font-weight: 400;
`;

const Heading2 = styled(Text)`
  font-size: 4.5vh;
  font-weight: 400;
  white-space: nowrap;
`;

const Heading3 = styled(Text)`
  font-size: 3.4vh;
  font-weight: 400;
  white-space: nowrap;
`;

const Heading4 = styled(Text)`
  font-size: 3.5vh;
  font-weight: 400;
  color: #52301D;
`;

const ParagraphModal = styled(Text)`
  color: black;
  font-size: 2.2vh;
  font-weight: 400;
  font-family: 'font-pacifico';
  //white-space: nowrap;
`;

const Paragraph = styled(Text)`
  color: black;
  font-size: 2.4907vh;
  font-weight: 400;
  font-family: 'font-pacifico';
  //white-space: nowrap;
`;

const Paragraph2 = styled(Text)`
  font-size: 1.8vh;
  font-weight: 400;
  padding: 0px 0px 0px 27px;
  display: block;
  white-space: nowrap;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { Text, Paragraph, Paragraph2, Heading, Heading2, Heading3, Heading4, Heading5, ParagraphModal };
