import React from 'react';
import { useState, useEffect } from 'react';
import { auth } from '../libs/authentication'
import Spinner from './Spinner';
import ErrorPage from './ErrorPage';
import Online from './Connect';

const Authpage = ({sessionParam}) => {
  const [loading, setLoading] = useState(true);
  const [error, setErrorState] = useState(false);


  async function authenticate(authData, Domain) {
    try {
      if ('lsale' in authData) {
        setLoading(false)
      }
      else if (authData.vendor === 'openwifi'){
        var password = 'Rl8zLPZ4w7L7'
        var username = (authData.client_mac_norm + '@' + Domain)
        var AuthUrl = `http://${authData.controller_ip}:${authData.controller_port}/logon?username=${username}&password=${password}&userurl=https://www.thinkwifi.online/blog/&lang=EN`
        window.location.replace(AuthUrl)
      } 
      else {
        const auth_result = await (auth(authData, Domain))
        if (auth_result.status) {
          setTimeout(() => {
                 setLoading(false);
             }, 2000);
        } else {
          setTimeout(() => {
            //setLoading(false);
            setErrorState(true);
          }, 2000);
        }
      }
      
    } catch(e) {
      //setLoading(false);
      setErrorState("Authentication Failed")
    } finally {

    }
  };


  useEffect(()=>{
    authenticate(sessionParam, 'morningside')
  },[sessionParam])

    if (error) return <ErrorPage errMsg={error}/>;
    if (loading) return <Spinner />;
    if (!loading) {
        return <Online sessionParam={sessionParam} />;
    }

}

export default Authpage;