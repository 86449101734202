const Colours = {
    PRIMARY_BLUE: '#0073b1',
    // LIGHT_BLUE: '#1a77b9',
    LIGHT_BLUE: '#212121',
    DOT_DARK: '#1A77B9',
    DOT_LIGHT: '#95C6E6',
    DEMO_GREY: '#D3D3D3',
    DEMO_DARK_GRAY: '#58595B',
    DEMO_LIGHT_BLUE: '#8AB5D8',
    SUPRISED_PURPLE: '#C937B7',
    VIOLET_PURP: '#AD00B1',
    THEME_OR: '#0073b1',
    TAN_PINK: '#ff385c',
    G_BLACK: '#000000',
    B_BROWN: '#2f3325',
    BROWN: '#4f2d1a',
  };
  
  export default Colours;
  