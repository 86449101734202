import './App.css';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { prechecks } from './libs/apiCalls';
import queryString from 'query-string';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Page1 from './pages/Page1Intro';
import Online from './pages/iphoneOnline';


import Spinner from './pages/Spinner';
import ErrorPage from './pages/ErrorPage';
const urlParams = queryString.parse(window.location.search);

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setErrorState] = useState();
  const [sessionData, setSessionData] = useState();

  const [PrecheckData, setPrecheckData] = useState();
  const [DoPreChecks, setDoPreChecks] = useState(false);

  async function getPrechecks() {
    try {
      const precheckResult = (await prechecks(urlParams)).data
      if ('status' in precheckResult){
        setErrorState(true)
      } else if ('detail' in precheckResult){
        setErrorState(true)
      }else {
        setPrecheckData(precheckResult)
      }
    } catch(e) {
      setErrorState("Prechecks Failed")
    } finally {

    }
  };

  useEffect(() => {
    if (urlParams) {
          var sessionData1 = {...urlParams, ...PrecheckData};
          sessionData1['session'] = 'session1'
          setSessionData(sessionData1);
          setLoading(false);
    }
  },[PrecheckData])

  useEffect(() => {
    if (DoPreChecks) {
        getPrechecks()
      }
  }, [DoPreChecks]);

  useEffect(() => {
    const isEmpty = Object.keys(urlParams).length === 0;
    if (isEmpty) {
    console.log('I am in error state')
    setErrorState(true)
  } else {
    setDoPreChecks(true);
  }
  },[]);

// useEffect(() => {
//   if (loading) {
//     setTimeout(() => {
//     setLoading(false);
//   }, 2000);
//   }
// }, [loading]);

if (error) return <ErrorPage errMsg={error}/>;
if (loading) return <Spinner />;

return (
  <div className="App morning-side-bg">
      <BrowserRouter>
      {/* <SSRProvider> */}
        {/* <Router> */}
          <Routes>
            <Route exact path="/" element={<Page1 sessionParam={sessionData}/>} />
            <Route exact path="/online" element={<Online sessionParam={sessionData}/>} />
          </Routes>
        {/* </Router> */}
      {/* </SSRProvider> */}
      </BrowserRouter>

    </div> 

  );
}

export default App;
