import styled from 'styled-components';

const AppContainer = styled.div`
  margin: auto;
  height: 100vh;
  background: white;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15% 60% 10% 15%;
  grid-template-areas: "Header" "Section" "Navigation" "Footer";

  justify-items: center;

  max-width: 720px;

  @media (orientation: landscape) {
    // max-width: 40%;
    max-width: 60vh;
  } 
`;

const HeaderContainer = styled.div`
  grid-area: Header;
  justify-self: stretch;
`;

const SectionContainer = styled.div`
  grid-area: Section;
  // justify-items: center;


  display: flex;
  flex-direction: column;

  // display: ${(props) => ((props.page === 1) ? 'block' : 'grid')};
  // grid-template-columns: 1fr;
  // grid-template-rows: 15% 70% 15%;
  // grid-template-rows: 15% 50% 35%;

`;

const PointContainer = styled.div`
  margin: 0% 5%;
  justify-items: center;
`;

const NavigationContainer = styled.div`
  grid-area: Navigation;
`;

const FooterContainer = styled.div`
  grid-area: Footer;
  justify-self: stretch;
`;

const BtnContainer = styled.div`
  margin-top: 2vh;
  width: 18vh;
  //height: 50%;;
  margin-left: auto;
  margin-right: auto;
`;

export {
  AppContainer,
  HeaderContainer,
  SectionContainer,
  PointContainer,
  NavigationContainer,
  FooterContainer,
  BtnContainer,
};
