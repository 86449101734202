import '../App.css';
import { useState } from 'react';
import { ClientJS } from 'clientjs';


import styled from 'styled-components';

//import Footer from './Footer';

import Logo from '../images/moring-side-logo.png';

//import Terms from '../components/TermsModal';

import Btn from '../components/Button';
import Text from '../components/Text';

import {
    SectionContainer,
    BtnContainer,

  } from '../components/ResponsiveLayout';
  import {
    TextContainer,
  } from '../components/Layout';

const CustomTextContainer = styled(TextContainer)`
  margin: 2vh 5vh;
`;


const client = new ClientJS();
const client_os = client.getOS();

function Online({sessionParam})  {

    const [NextPage, setNextPage] = useState(false);
    //const [modalState, setModalState] = useState(false);
    console.log(client_os)
    const Ulocation = window.location.hostname
    const USearch = window.location.search
    console.log(Ulocation)
    if (NextPage) {
      window.location.href = "https://www.morningsideshops.co.za/"
    };
  if (Ulocation === 'localhost') {
    var destUrl = `http://localhost:3000/online/${USearch}`
  } else {
    var destUrl = `https://morningside.thinkwifizone.com/online/${USearch}`
  }

  if (client_os === 'iOS') {
    window.location.href = destUrl
  }
  return (

            <div className='connected'>
                <section id="page">

                        <header>
                            
                        </header>    


                    <main>
                        <SectionContainer>
                            <CustomTextContainer style={{ flex: 'none' }}>
                            <Text.Text>
                            <br />
                            {' '}
                            <Text.Heading5 className='font-pacifico b-black'>
                                Congratulations
                            </Text.Heading5>
                            <br />
                            {' '}
                            <br />
                            {' '}
                            <Text.Paragraph className='font-filson-bold b-brown'>
                              You are now connected to
                            <br />
                            My Morningside Free Wi-Fi!
                            </Text.Paragraph>
                            <br />
                            {' '}
                            <br />
                            {' '}
                            <p className='font-filson-bold welcome-small'>Enjoy 2 Hours of Free Internet</p>
                            </Text.Text>
                            </CustomTextContainer>
                        </SectionContainer>

                    </main>
                    <nav>
                        <BtnContainer>
                            <Btn onClick={() => setNextPage(true)}>Browse</Btn>
                        </BtnContainer>
                    </nav>
                    <footer>
                      <div>
                        <img className="logo" src={Logo} alt="thinkLogo" /> 
                      </div>
                    </footer>
                </section>
          </div>
  );
};


export default Online;
