import '../App.css';
import { useState } from 'react';

import styled from 'styled-components';

//import Footer from './Footer';
import Authpage from './Auth_page';

import Logo from '../images/moring-side-logo.png';

//import Terms from '../components/TermsModal';

import Btn from '../components/Button';
import Text from '../components/Text';

import {
    SectionContainer,
    BtnContainer,

  } from '../components/ResponsiveLayout';
  import {
    TextContainer,
  } from '../components/Layout';

const CustomTextContainer3 = styled(TextContainer)`
  margin: 2vh 2vh;
`;
const CustomTextContainer4 = styled(TextContainer)`
  margin: 2vh 2vh;
`;


function Description({sessionParam})  {

    const [NextPage, setNextPage] = useState(false);

        if (NextPage) {

        return <Authpage sessionParam = {sessionParam} />;

        }

  return (

            <div className='description'>
                <section id="page">

                        <header>
                          <div>
                            <img className="logo" src={Logo} alt="thinkLogo" /> 
                          </div>
                        </header>    


                    <main>

                    <CustomTextContainer3 style={{ flex: 'none' }}>
                          <Text.Text>
                            <Text.Heading className='font-pacifico'>
                              Enjoy
                            </Text.Heading>
                            <br />
                            {' '}
                            <Text.Paragraph className='font-filson-bold b-brown'>
                              a connected shopping <br/>
                              experience
                            </Text.Paragraph>
                          </Text.Text>
                      </CustomTextContainer3>

                        <SectionContainer>
                            <CustomTextContainer4 style={{ flex: 'none' }}>
                            <Text.Text>
                              <Text.Heading2 className='font-pacifico b-black'>
                                Trading Hours
                              </Text.Heading2>
                              <br />
                              {' '}
                              <Text.Paragraph className='font-filson-bold b-brown'>
                              Mon-Sat
                              <br />
                              {' '}
                              09h00 to 18h00
                              <br />
                              {' '}
                              Sun and Public Holidays
                              <br />
                              {' '}
                              09h00 to 15h00
                              </Text.Paragraph>
                              <br />
                              {' '}
                              <Text.Heading2 className='font-pacifico b-black'>
                                Parking
                              </Text.Heading2>
                              <br />
                              {' '}
                              <Text.Paragraph className='font-filson-bold display-linebreak b-brown'>
                              ZAPPER for Parking
                              {'\n'}
                              SKIP the pay station {'\n'} SCAN the ticket to pay {'\n'} BOOM you're away
                              </Text.Paragraph>
                            </Text.Text>
                            </CustomTextContainer4>
                        </SectionContainer>

                    </main>
                    <nav>
                        <BtnContainer>
                            <Btn onClick={() => setNextPage(true)}>Connect</Btn>
                        </BtnContainer>
                    </nav>
                    <footer>

                    </footer>
                </section>
          </div>
  );
};


export default Description;
