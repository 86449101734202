import '../App.css';
import { useState, useRef } from 'react';
import ReactGA from "react-ga4";
import styled from 'styled-components';

import Check from './Checkbox';
//import Footer from './Footer';
//import Authpage from './Auth_page';
import Descript from './Description';

import Logo from '../images/moring-side-logo.png';

import Terms from '../components/TermsModal';

import Btn from '../components/Button';
import Text from '../components/Text';

import {userinfo} from '../libs/apiCalls'

import {
    SectionContainer,
    BtnContainer,
    PointContainer,

  } from '../components/ResponsiveLayout';
  import {
    TextContainer,
  } from '../components/Layout';

  // Boostrap
import Form from 'react-bootstrap/Form';

  const CustomTextContainer = styled(TextContainer)`
  margin: 2vh 2vh;
`;

ReactGA.initialize("G-CC1926HYZD");

function Home({sessionParam})  {
    const [NextPage, setNextPage] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [Name, setName] = useState("")
    const [Surname, setSurname] = useState("")
    const [Email, setEmail] = useState("")
    const check = useRef();
    const [error, setError] = useState(null);

    const sessionData = sessionParam

    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }
  
    const handleChange = event => {
      if (!isValidEmail(event.target.value)) {
        setError('Email is invalid');
      } else {
        setError(null);
      }
  
      setEmail(event.target.value);
    };

    async function handleSubmit (event) {
      event.preventDefault();


      var userinput =  {};

          userinput["name"] = Name;
          userinput["surname"] = Surname;
          userinput["email"] = Email;
          userinput["ap_mac"] = sessionData.ap_mac

          console.log("++++++++")
          console.log(userinput)
          console.log("--------")
          console.log(sessionData)

              // Validate the form
          if ( Name === null || Name === "") {
            setError("Please enter your Name")
        } else if (Surname === null || Surname === "") {
            setError("Please enter your Surname")
        } else if (Email === null || Email === "") {
            setError("Please enter your Email")
        } else {
          if (check.current.checked) {

            let inputresult = (await userinfo(sessionData, userinput)).data;
            if ( inputresult.status === "success") {
              setNextPage(true)
            }
            else {
              setError('unknown error');
            }
          } else {
            setError('Check the checkbox');
            setNextPage(false)
          }
        }

  };

    if (modalState) {

        return <Terms setModal={{ setModalState}} />;

        }
      if (NextPage) {

        return <Descript sessionParam = {sessionParam} />;

        }


  return (

            <div className='page-1'>
                <section id="page">
                    <header>
                        {/* <div>
                          <img className="logo" src={Logo} alt="thinkLogo" /> 
                        </div> */}

                    </header>    
                    <main>

                        <SectionContainer>
                            <CustomTextContainer className="custom-container" style={{ flex: 'none' }}>
                                <Text.Text>
                                  {/* <Text.Heading className='font-pacifico'>
                                    Welcome
                                  </Text.Heading> */}
                                  <h1 className='font-pacifico welcome-big'>Welcome</h1>
                                  <p className='font-filson-bold welcome-small'>to Morningside Shopping Centre</p>
                                </Text.Text>
                            </CustomTextContainer>
                            <CustomTextContainer style={{ flex: 'none' }}>
                              <Text.Text>
                                <Text.Heading3 className='font-filson-bold b-brown'>
                                  Our FREE Wi-Fi is now
                                </Text.Heading3>
                                <br />
                                {' '}
                                <Text.Heading2 className='font-filson-bold b-brown'>
                                  Bigger and Better!
                                </Text.Heading2>
                                <br />
                                {' '}
                              </Text.Text>
                            </CustomTextContainer>
                            <PointContainer className='point-check'>
                            <div className="container">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="gg-check">
                                      <Text.Paragraph2 className='font-filson-bold b-brown'>
                                        Uncapped
                                      </Text.Paragraph2>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="gg-check">
                                      <Text.Paragraph2 className='font-filson-bold b-brown'>
                                        High-speed
                                      </Text.Paragraph2>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="gg-check">
                                      <Text.Paragraph2 className='font-filson-bold b-brown'>
                                        Secure
                                      </Text.Paragraph2>  
                                    </div>                                  
                                  </div>
                                </div>
                              </div>
                            </PointContainer>
                            </SectionContainer>

                    </main>
                    <nav>
                    <SectionContainer>

                      <Form className='form-wrapped' onSubmit={handleSubmit}>

                      <h4 className='font-filson-bold form-heading text-center pt-2'>Registration</h4>
                          <Form.Group className="mb-1">
                              <Form.Control
                                className='style-test font-filson-bold'
                                type="text"
                                placeholder="Name"
                                value={Name}
                                onChange={(e) => setName(e.target.value)}
                                />
                          </Form.Group>
                          <Form.Group className="mb-1">
                              <Form.Control
                                className='style-test font-filson-bold'
                                type="text"
                                placeholder="Surname"
                                value={Surname}
                                onChange={(e) => setSurname(e.target.value)}
                                />
                          </Form.Group>
                          <Form.Group className="mb-1" controlId="formBasicEmail">
                              <Form.Control
                              className='style-test font-filson-bold'
                              type="email"
                              placeholder="Email"
                              value={Email}
                              //onChange={(e) => setEmail(e.target.value)}
                              onChange={handleChange} 
                              />
                          </Form.Group>

                          <Form.Group className="mb-1 custom-check " controlId="formBasicCheckbox" >
                              <Form.Check
                              className='text-white font-filson-bold geekmark'
                              type="checkbox"
                              //defaultChecked={true}
                              ref={check}
                              />
                                <label htmlFor="checkbox">
                                <Check setModal={{ setModalState }}/>
                                </label>
                          </Form.Group>

                          <Form.Label className='error-message'>{error}</Form.Label>
                          
                          <BtnContainer className='button-style'>
                              <Btn type="submit" className='font-filson-bold'>Connect Now</Btn>
                          </BtnContainer> 
                      </Form>
                      </SectionContainer>
                    </nav>
                    <footer>
                      {/* <Footer step={1} setModal={{ setModalState }}/>

                      {modalState ? <Terms setModal={{ setModalState }} /> : null} */}
                        <div>
                          <img className="logo" src={Logo} alt="thinkLogo" /> 
                        </div>
                    </footer>
                </section>
              </div>
  );
};


export default Home;
