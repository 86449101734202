import React from 'react';
import styled from 'styled-components';

import Text from '../components/Text';

const FooterContainer = styled.div`
  //display: inline;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
 //min-height: 10vh;
  box-sizing: border-box;
`;

const InfoTextParagraph = styled(Text.Paragraph)`
  color: #000;
  font-size: 1.8vh;
  padding-left: 35px;
//   visibility: ${(props) => (props.step !== 1 ? "hidden" : "visible")};
`;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 1.8vh;
  margin-left: 1vh;
  color: #000;
`;


const Footer = (props) => {
  // const [showModal, setShowModal] = useState(false);
  const { setModal } = props;

  return (
    <>
      <FooterContainer>

            <InfoTextParagraph className='font-filson-bold'>
               I agree to the
              <LinkButton onClick={() => setModal.setModalState(true)}> 
                T&C's               
              </LinkButton>
            </InfoTextParagraph>

      </FooterContainer>
    </>
  );
};

export default Footer;
