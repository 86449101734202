import styled from 'styled-components';
import Colors from './Colors';

const Button = styled.button`
  font-family: 'Open Sans', sans-serif;
  font-weight:500;
  width: 100%;
  height: 45px;
  max-height: 100%;
  background-color: ${Colors.B_BROWN};
  border-radius: 200px;
  color: white;
  font-size: 2vh;
  border: none;

  :disabled {
    background-color: ${Colors.DEMO_GREY};
  }
`;

export default Button;
