import '../App.css';
import { useState } from 'react';


import styled from 'styled-components';

import Logo from '../images/moring-side-logo.png';

import Btn from '../components/Button';
import Text from '../components/Text';

import {
    SectionContainer,
    BtnContainer,

  } from '../components/ResponsiveLayout';
  import {
    TextContainer,
  } from '../components/Layout';

const CustomTextContainer = styled(TextContainer)`
  margin: 2vh 5vh;
`;


function Online({sessionParam})  {

    const [NextPage, setNextPage] = useState(false);

    if (NextPage) {
      window.location.href = "https://www.morningsideshops.co.za/"
    };

  return (

            <div className='connected'>
                <section id="page">
                        <header>
                            
                        </header>    
    
    
                        <main>
                            <SectionContainer>
                                <CustomTextContainer style={{ flex: 'none' }}>
                                <Text.Text>
                                <br />
                                {' '}
                                <Text.Heading5 className='font-pacifico b-black'>
                                    Congratulations
                                </Text.Heading5>
                                <br />
                                {' '}
                                <br />
                                {' '}
                                <Text.Paragraph className='font-filson-bold b-brown'>
                                  You are now connected to
                                <br />
                                My Morningside Free Wi-Fi!
                                </Text.Paragraph>
                                <br />
                                {' '}
                                <br />
                                {' '}
                                <p className='font-filson-bold welcome-small'>Enjoy 2 Hours of Free Internet</p>
                                </Text.Text>
                                </CustomTextContainer>
                            </SectionContainer>
    
                        </main>
                        <nav>
                            <BtnContainer>
                                <Btn onClick={() => setNextPage(true)}>Browse</Btn>
                            </BtnContainer>
                        </nav>
                        <footer>
                          <div>
                            <img className="logo" src={Logo} alt="thinkLogo" /> 
                          </div>
                        </footer>
                </section>
          </div>
  );
};


export default Online;
