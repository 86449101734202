import React from 'react';
import '../App.css';
import styled from 'styled-components';

import Text from '../components/Text';

import {
  TextContainer,
} from '../components/Layout';

const TextContainerP2 = styled(TextContainer)`
  margin: 8vh;
  flex-direction: column;
`;

const Info = styled(Text.Text)`
  font-size: 2vh;
`;

const HeaderText = styled(Text.Text)`
  margin-bottom: 1vh;
`;

function Error()  {

  return (

  <div className='error'>
    <section id="page">
            <header>

            </header>

            <main>
                <div className='trigger'>
                  <TextContainerP2>
                    <HeaderText>
                      Something went wrong
                    </HeaderText>
                    <Info>
                      Please try again later
                    </Info>
                    <Info>
                      [ERROR:]
                    </Info>
                  </TextContainerP2>
                </div>
            </main>

            <nav>
                {/* <button>Continue</button> */}
            </nav>

            <footer>

            </footer>
    </section>
  </div>
  );
};


export default Error;
