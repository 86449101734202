import * as apiCall from './ajaxConnector'
//import * as queryString from 'query-string';

const LOGS_BASE_API_URL = 'https://logs.thinkwifizone.com'
const BASE_API_URL = 'https://apigw.thinkwifizone.com'
const token = 'akljnv13bvi2vfo0b0bw'
//const urlParams = queryString.parse(window.location.search);
//const ap_mac = urlParams.mac
//const client_mac = urlParams.client_mac
//adding test for branches
//adding some more changes

export const userinfo = async (sessionData, userinfo) => {
    const result = await apiCall.post(`${BASE_API_URL}/api/v1/users/${sessionData.partner}/add/user_info`, userinfo, { headers: {"Authorization" : `Bearer ${token}`} })
    return result
}

export const logs = async (logs,sessionData) => {
    //let result = 'ok'
    const result = await apiCall.post(`${LOGS_BASE_API_URL}/logging/${logs.event}/${sessionData.client_mac_norm}/${sessionData.ap_mac_norm}`, logs)
    return result
}

export const prechecks = async (urlParms) => {
        const result = await apiCall.post(`${BASE_API_URL}/api/v1/prechecks`, urlParms, { headers: {"Authorization" : `Bearer ${token}`} })
        return result 
}

export const getAd = async (urlParms) => {
    const result = await apiCall.get(`${BASE_API_URL}/api/v1/getAd/${urlParms.ap_mac_norm}/${urlParms.client_mac_norm}`, { headers: {"Authorization" : `Bearer ${token}`} })
    return result 
}

export async function prechecks1(urlParms){
    const result = await apiCall.post(`${BASE_API_URL}/api/v1/prechecks`, urlParms, { headers: {"Authorization" : `Bearer ${token}`} })
    console.log(result.data)
    return result 
}





