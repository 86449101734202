import * as apiCall from './ajaxConnector'
//import { chilliController } from './chilli/ChilliLibrary';

const BASE_API_URL = 'https://apigw.thinkwifizone.com'
const token = 'akljnv13bvi2vfo0b0bw'

//Authentication for Ruckus and Coovachilli
export const auth = async (sessionData, userDomain) => {

    var password = 'Rl8zLPZ4w7L7'
    var username = (sessionData.client_mac_norm + '@' + userDomain)

    
    if (sessionData.vendor === 'ruckus') {
        console.log('this is ruckus ' + userDomain)
        sessionData['username'] = username;
        let auth_result = await (apiCall.post(`${BASE_API_URL}/api/v1/auth/thinkzone/${sessionData.vendor}/session`, sessionData, { headers: {"Authorization" : `Bearer ${token}`} }))
        if ('error' in auth_result.data){
            return {'status': false}
        } else {
            return {'status': true}
        }
        
        
    } else if (sessionData.vendor === 'openwifi'){
        console.log('this is openwifi ' + userDomain)
        var AuthUrl = `http://${sessionData.controller_ip}:${sessionData.controller_port}/logon?username=${username}&password=${password}&userurl=https://www.thinkwifi.online/blog/&lang=EN`
        window.location.replace(AuthUrl)
        // var auth_response = await apiCall.get(AuthUrl,{headers: {"Access-Control-Allow-Origin":"*"}})
        // console.log = (auth_response)
        // return {'status': true}
        // if (auth_response.status === 200) {
        //     return {'status': true};
        //  } else {
        //   return {'status': true}
        //  }
        // console.log(AuthUrl)
        // console.log(auth_response)
        // if (auth_response.clientState === 1){
        //     return {'status': true}
        // } else {
        //     return {'status': false}
        // }

        
        // var chilliController = require('./chilli/ChilliLibrary')

        // function authResponse (cmd ) {
        //     // UNKNOWN:-1 , NOT_AUTH:0 , AUTH:1 , AUTH_PENDING:2 , AUTH_SPLASH:3
        //     if ( chilliController.clientState == -1 ) {
        //         console.log("clientstate UNKNOWN")
        //     }
        //     else if ( chilliController.clientState == 0 ) {
        //         console.log("clientstate NOT_AUTH")
        //     }
        //     else if ( chilliController.clientState == 1 ) {
        //         console.log("clientstate AUTH")
        //     }
        //     else if ( chilliController.clientState == 2 ) {
        //         console.log("clientstate AUTH_PENDING")
        //     }
        //     else if ( chilliController.clientState == 3 ) {
        //         console.log("clientstate AUTH_SPLASH")
        //     }
        // }

        // chilliController.host = sessionData.controller_ip
        // chilliController.port = sessionData.controller_port
        // // chilliController.uamService = "https://hotspot.trifork.nl/uam.js"
        // chilliController.onUpdate = authResponse
        // chilliController.logon(username, password)    
        // console.log(chilliController)    

    }

}